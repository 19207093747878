.p-button {
    &.p-button {
        @extend .fw-medium;

        padding: rem-calc(8px) rem-calc(16px);
        text-transform: uppercase;

        .p-button-label {
            @extend .fs-13;
            text-transform: inherit;
            letter-spacing: 0.5px;
            line-height: initial;
        }
    }

    .ng-fa-icon {
        font-size: rem-calc(16px);
    }

    &:not(.p-button-icon-only) .ng-fa-icon {
        margin-left: rem-calc(-6px);
        margin-right: rem-calc(6px);
    }

    .svg-inline--fa {
        display: block;
    }

    &.p-button-sm {
        padding: rem-calc(6px) rem-calc(10px);

        .p-button-label {
            @extend .fs-11;
        }

        &:not(.p-button-icon-only) .ng-fa-icon {
            margin-left: rem-calc(-2px);
        }

        .ng-fa-icon {
            font-size: rem-calc(14px);
        }
    }

    &.p-button-lg {
        padding-top: rem-calc(10.5px);
        padding-bottom: rem-calc(10.5px);

        .p-button-label.p-button-label {
            @extend .fs-15;
        }

        &:not(.p-button-icon-only) .ng-fa-icon {
            margin-left: rem-calc(-6px);
        }
    }

    &.p-button-icon-square.p-button-icon-square.p-button-icon-square {
        padding: 0.4rem;
        width: rem-calc($squareIconButtonSize);
        height: rem-calc($squareIconButtonSize);
    }
}

.p-button-primary.p-button-primary.p-button-primary {
    border: 1px solid $color-primary;

    &:disabled {
        border-color: transparent;
    }
}
