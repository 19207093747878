// Required
@import "../vars";
@import "bootstrap/scss/functions";

//$primary: $color-primary; // Remove for now
$body-bg: $color-gray-1;

$font-family-sans-serif: $font-family;
$font-family-base: $font-family-sans-serif;

$h1-font-size: rem-calc(42px);
$headings-font-weight: 700;

@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

$colors: map-remove($colors, "indigo", "purple", "pink", "red", "orange", "yellow", "green", "teal", "cyan");

$theme-colors: map-merge(
    $theme-colors,
    (
        "white": $color-white,
        "black": $color-black,
        "gray-1": $color-gray-1,
        "gray-2": $color-gray-2,
        "gray-3": $color-gray-3,
        "gray-4": $color-gray-4,
        "status-1": $color-status-1,
        "status-2": $color-status-2,
        "status-3": $color-status-3,
        "status-4": $color-status-4,
        "status-5": $color-status-5,
        "status-6": $color-status-6,
        "status-7": $color-status-7,
        "status-8": $color-status-8,
        "status-9": $color-status-9,
    )
);

@import "bootstrap/scss/reboot";

$utilities: (
    "background-color": (
        property: background-color,
        class: bg,
        values: $theme-colors,
    ),
    "color": (
        property: color,
        class: color,
        values: $theme-colors,
    ),
    "text-transform": (
        property: text-transform,
        class: text-transform,
        values: (
            none: none,
            uppercase: uppercase,
        ),
    ),
    "text-decoration": (
        property: text-decoration,
        class: text-decoration,
        values: (
            none: none,
            underline: underline,
        ),
    ),
    "width": (
        property: width,
        class: w,
        values: (
            auto: auto,
            100: 100%,
        ),
    ),
);

@import "bootstrap/scss/utilities/api";
@import "bootstrap/scss/bootstrap-utilities";
@import "bootstrap/scss/bootstrap-grid";
