.p-multiselect {
    @extend %fs-13;
    width: 100%;
}

.p-multiselect-label-container {
    .p-multiselect-label {
        padding-top: rem-calc(9px);
        padding-bottom: rem-calc(9px);
    }
}

.p-multiselect-items {
    .p-multiselect-item {
        @extend %fs-13;
    }
}

.p-multiselect-panel {
    .p-multiselect-header {
        padding-top: rem-calc(9px);
        padding-bottom: rem-calc(9px);
    }
}
