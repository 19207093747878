@import "../global";

$buttonRadius: 4px;

p-paginator .p-paginator {
    justify-content: flex-end;

    .custom-paginator-item {
        border-radius: 0;
        border: 1px solid $color-gray-2;
        margin: 0 0 0 -1px;
        height: $squareIconButtonSize;
        width: $squareIconButtonSize;
        min-width: $squareIconButtonSize;
        font-size: 13px;
    }

    .p-paginator-pages .p-paginator-page {
        @extend .custom-paginator-item;

        &:not(.p-highlight):hover {
            @extend .custom-paginator-item;
        }
    }

    .p-paginator-first {
        @extend .custom-paginator-item;
        color: $color-primary;
        border-top-left-radius: $buttonRadius;
        border-bottom-left-radius: $buttonRadius;
        &.p-paginator-first.p-paginator-first:hover {
            @extend .custom-paginator-item;
            color: $color-primary;

            border-top-left-radius: $buttonRadius;
            border-bottom-left-radius: $buttonRadius;
        }
    }

    .p-paginator-prev {
        @extend .custom-paginator-item;
        color: $color-primary;
        &.p-paginator-prev.p-paginator-prev:hover {
            @extend .custom-paginator-item;
            color: $color-primary;
        }
    }

    .p-paginator-next {
        @extend .custom-paginator-item;
        color: $color-primary;
        &.p-paginator-next.p-paginator-next:hover {
            @extend .custom-paginator-item;
            color: $color-primary;
        }
    }

    .p-paginator-last {
        @extend .custom-paginator-item;
        color: $color-primary;
        border-top-right-radius: $buttonRadius;
        border-bottom-right-radius: $buttonRadius;

        &.p-paginator-last.p-paginator-last:hover {
            @extend .custom-paginator-item;
            color: $color-primary;
            border-top-right-radius: $buttonRadius;
            border-bottom-right-radius: $buttonRadius;
        }
    }

    .p-disabled {
        color: $color-gray-4;
        border-color: $color-gray-2;
    }

    .p-highlight.p-highlight.p-highlight.p-highlight {
        background: $color-primary;
        border-color: $color-gray-2;
        color: white;
    }

    .p-dropdown {
        height: $squareIconButtonSize;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border: 1px solid $color-gray-2;

        .p-dropdown-trigger {
            width: 14px;
            margin-right: 12px;
            margin-left: 6px;
        }
    }
}

.p-paginator-bottom.p-paginator {
    border: none;
    padding-right: 7px;
}
