.wrapper-nav {
    position: sticky;
    top: $header-height;
    z-index: map-get($z-index, nav);

    background-color: $color-white;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
}

.wrapper--shadow {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
}
