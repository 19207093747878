%fs-23 {
    font-size: rem-calc(23px);
}

%fs-15 {
    font-size: rem-calc(15px);
}

%fs-13 {
    font-size: rem-calc(13px);
}

%fs-11 {
    font-size: rem-calc(11px);
}

%fw-regular {
    font-weight: 500;
}

%fw-medium {
    font-weight: 400;
}

%fw-bold {
    font-weight: 700;
}
