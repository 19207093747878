@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

.fs-23.fs-23 {
    @extend %fs-23;
}

.fs-15.fs-15 {
    @extend %fs-15;
}

.fs-13.fs-13 {
    @extend %fs-13;
}

.fs-11.fs-11 {
    @extend %fs-11;
}

.fw-regular.fw-regular {
    @extend %fw-regular;
}

.fw-medium.fw-medium {
    @extend %fw-medium;
}

.fw-bold.fw-bold {
    @extend %fw-bold;
}

.text-ellipsis {
    display: block;
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}
