@import "../../vars";

$primaryColor: $color-primary;
$primaryTextColor: #ffffff;
$accentColor: $color-black;
$accentTextColor: #ffffff;

@import "./themes/material/material-light/variables";
@import "./themes/material/compact";

$containerSmall: 900px;
$containerDesktopWidth: 1100px;
$containerWideWidth: 1140px;
$containerExtraWideWidth: 1320px;

// To enable support for browsers that do not support @media queries,
// (IE <= 8, Firefox <= 3, Opera <= 9) set $mq-responsive to false
// Create a separate stylesheet served exclusively to these browsers,
// meaning @media queries will be rasterized, relying on the cascade itself
$mq-responsive: true;

// Name your breakpoints in a way that creates a ubiquitous language
// across team members. It will improve communication between
// stakeholders, designers, developers, and testers.

// Add missing $xxl (if needed!)
$xxl: 1400px;

$mq-breakpoints: (
    mobile: $sm,
    tablet: $md,
    desktop: $lg,
    wide: $xl,
    extra-wide: $xxl,
);

// Define the breakpoint from the $mq-breakpoints list that should
// be used as the target width when outputting a static stylesheet
// (when $mq-responsive is set to 'false').
$mq-static-breakpoint: desktop;

// If you want to display the currently active breakpoint in the top
// right corner of your site during development, add the breakpoints
// to this list, ordered by width. For example: (mobile, tablet, desktop).
// $mq-show-breakpoints: (mobile, tablet, desktop, wide, extra-wide);

$fontFamily: $font-family;

$inputTextFontSize: rem-calc(13px);
$inputBorder: 1px solid $color-gray-3;
$inputHoverBorderColor: $color-gray-4;
$inputFocusBorderColor: $color-gray-3;
$inputErrorBorderColor: $color-primary;

$errorColor: $color-primary;

$checkboxWidth: $radioCheckSize;
$checkboxHeight: $radioCheckSize;
$checkboxBorder: $radioCheckBorder;

$radiobuttonWidth: $radioCheckSize;
$radiobuttonHeight: $radioCheckSize;
$radiobuttonBorder: $radioCheckBorder;
$radiobuttonIconSize: rem-calc(14px);

$dividerVerticalPadding: rem-calc(3px);

$tableBodyCellPadding: rem-calc(17px) rem-calc(8px);
$tableHeaderCellPadding: $tableBodyCellPadding;

$tagPadding: rem-calc(3px) rem-calc(6px);

$dialogHeaderFontSize: rem-calc(15px);

@import "./designer/_components";
@import "./themes/material/extensions/vendor_extensions";
