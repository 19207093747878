@import "sass-mq";

html {
    height: 100%;

    body {
        height: 100%;
    }
}

.container {
    width: 100%;
    padding-left: $container-padding;
    padding-right: $container-padding;
    margin-left: auto;
    margin-right: auto;

    @include mq($from: desktop) {
        max-width: $container-max-width;
    }
}

.fluid-container {
    width: 100%;
}

.container-small {
    width: 100%;
    padding: 0 $spacer;
    margin: 0 auto;

    @include mq($from: desktop) {
        max-width: $containerSmall;
    }
}
