.p-inputtext {
    &#{&}:active,
    &#{&}:focus {
        box-shadow: 0px 0px 0px 2px $color-gray-3;
    }

    display: block;
    width: 100%;
    padding: rem-calc(9px) rem-calc(12px);

    &#{&}#{&}-sm {
        @extend .fs-11;
        padding: rem-calc(8px) rem-calc(12px);
    }

    &#{&}#{&}-lg {
        @extend .fs-15;
        padding: rem-calc(12.5px) rem-calc(12px);
    }

    &.p-component:disabled {
        border-color: $color-gray-2;

        color: rgba($color-gray-4, 0.6);
        background-color: $color-gray-1;

        opacity: 1;
    }

    &.ng-invalid {
        color: $color-primary;
    }
}

.p-field {
    display: flex;
    flex-direction: column;
    margin-bottom: rem-calc(4px);

    & > label {
        @extend %fs-13;
        @extend %fw-regular;

        margin-bottom: 0;
        letter-spacing: rem-calc(-0.25px);
    }

    & > small {
        @extend %fs-11;
    }
}

.p-form-block-title {
    @extend %fs-13;
    @extend %fw-regular;

    margin-bottom: rem-calc(16px);
}

.p-dropdown,
.p-calendar {
    width: 100%;
}

%input-icon {
    content: "";

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;

    background: center center no-repeat;
    background-size: contain;
}

.p-field .p-button.p-button-icon-only {
    padding: 0;
    width: rem-calc(35px);
}

.p-dropdown {
    &-trigger-icon {
        position: relative;

        &:after {
            @extend %input-icon;

            width: rem-calc(14px);
            height: rem-calc(8px);

            background-image: url("~/assets/images/icons/icon-chevron-down.svg");
        }
    }

    &-item {
        @extend %fs-13;
    }
}

.p-datepicker-trigger {
    position: relative;

    &:after {
        @extend %input-icon;

        width: rem-calc(16px);
        height: rem-calc(18px);

        background-image: url("~/assets/images/icons/icon-calendar.svg");
    }
}

.p-input-icon-right {
    .pi {
        position: absolute;
        right: 0;
        top: 0;

        margin-top: 0;
        height: 100%;
        width: rem-calc(35px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.p-checkbox.p-checkbox {
    .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
        content: none;
    }

    .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:after {
        content: "";

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(44deg);

        margin-top: rem-calc(-2px);
        width: rem-calc(7px);
        height: rem-calc(13px);

        border-right: 2px solid $color-white;
        border-bottom: 2px solid $color-white;
    }
}

.p-radiobutton-label.p-radiobutton-label,
.p-checkbox-label.p-checkbox-label {
    @extend .fs-13;
}
