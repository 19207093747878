.p-menu {
    padding: 0;
    border-radius: 0;

    .p-menuitem {
        @extend %fs-13;

        .p-menuitem-link {
            padding: 13px 17px;
        }
    }
}

.p-menu.p-menu-overlay {
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
}
