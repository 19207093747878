@use "sass:math";

.dialog-contents {
    padding: $container-padding;
    overflow-y: auto;
    position: fixed;
    width: 100%;
    top: 60px;
    bottom: 2.4 * $container-padding;
    left: 0;
}

.dialog-footer {
    border-top: 1px solid $color-gray-2;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: math.div($container-padding, 3) $container-padding math.div($container-padding, 3);
    display: flex;
    justify-content: flex-end;
}

.p-dialog {
    border-bottom-right-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;

    .p-dialog-header {
        border-top-right-radius: $borderRadius;
        border-top-left-radius: $borderRadius;
    }
}

.p-dialog.p-dynamic-dialog.p-component {
    .p-dialog-content {
        border-bottom-right-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
    }
}
