@use "sass:math";

.form-panel-contents {
    overflow: hidden;
    //position: fixed;
    //width: 100%;
    //top: 60px;
    //bottom: 2.4 * $container-padding;
    //left: 0;
    //div {
    //}
    padding: math.div($container-padding, 3);
}

.form-panel-footer {
    border-top: 1px solid $color-gray-2;
    //width: 100%;
    //position: fixed;
    //bottom: 0;
    //left: 0;
    display: flex;
    justify-content: flex-end;
    padding: math.div($container-padding, 3);
}
