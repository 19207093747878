@import "../global";

.p-dropdown {
    &.p-disabled {
        background-color: $color-gray-1;
    }
}

p-dropdown {
    .p-inputtext {
        &.p-dropdown-label:active,
        &.p-dropdown-label:focus {
            box-shadow: none;
        }
    }
}
