.p-sidebar.p-sidebar.p-sidebar {
    width: 25rem;
    padding: 0;

    .p-sidebar-contents {
        padding: $container-padding;
        overflow-y: auto;
        position: fixed;
        top: rem-calc(46px);
        bottom: 2.4 * $container-padding;
    }

    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 4px 5px 3px rgba(0, 0, 0, 0.12) !important;
}
