@import "global";

@import "./vendors/_index";
@import "./base/_index";
@import "./components/_index";

@import "ol/ol.css";

@import "ol-layerswitcher/dist/ol-layerswitcher.css";

a {
    text-decoration: none;
}
