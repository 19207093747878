.p-tabmenu {
    background-color: $color-white;

    .p-tabmenu-nav {
        @extend %fs-15;
        @extend %fw-medium;
        border-bottom: 0;
    }

    .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
        padding-left: rem-calc(6px);
        padding-right: rem-calc(6px);
        padding-top: 6px;
        padding-bottom: 6px;
        color: $color-black;
    }

    .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
        color: $color-black;
    }

    .p-tabmenuitem + .p-tabmenuitem {
        margin-left: rem-calc(24px);
    }

    .p-tabmenu-nav .p-tabmenu-ink-bar {
        height: 4px;
    }

    .ng-fa-icon {
        margin-right: rem-calc(6px);
    }

    &.p-tabmenu-small {
        .p-tabmenu-nav {
            @extend %fs-13;
            padding-bottom: rem-calc(4px);
        }
    }
}
