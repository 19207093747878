.dropdown {
    $body-offset: rem-calc(14px);

    position: relative;

    &__checkbox {
        display: none;
    }

    &__checkbox:checked ~ &__body {
        display: block;
    }

    &--with-hover:hover &__body {
        display: block;
    }

    &__trigger {
        @extend %fs-13;

        position: relative;
        padding-right: rem-calc(20px);
        cursor: pointer;

        &:after {
            content: "";

            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);

            width: rem-calc(11px);
            height: rem-calc(6px);

            background: url("~/assets/images/icons/icon-caret-solid-down.svg") center no-repeat;
        }
    }

    &--with-hover &__trigger {
        cursor: default;
    }

    &__body {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 1;

        display: none;
        min-width: 210px;
        margin-top: $body-offset;

        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        background-color: $color-white;

        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
        }

        li:not(:last-child) {
            border-bottom: 1px solid $color-gray-2;
        }

        .p-button {
            width: 100%;
            padding: rem-calc(13px) rem-calc(17px);
            border-radius: 0;

            text-align: left;
        }
    }

    &--with-hover:after {
        content: "";

        position: absolute;
        top: 100%;
        left: 0;

        width: 100%;
        height: $body-offset;
    }
}

.dropdown-dot {
    height: rem-calc(13px);
    width: rem-calc(13px);
    background-color: #bbb;
    border-radius: 50%;
    display: inline-table;
    margin-right: rem-calc(8px);
}
