.text-avatar {
    @extend %fs-13;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: rem-calc(32px);
    height: rem-calc(32px);
    border-radius: 100%;

    color: $color-white;
    background-color: #5c5c5c;

    text-transform: uppercase;
    letter-spacing: 0.2px;
}
