.p-datatable {
    .p-datatable-unfrozen-view {
        border-right: 1px solid $color-gray-2;

        .p-datatable-tbody {
            tr {
                td:first-child {
                    border-left: 0;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .p-datatable-thead {
            th:first-child {
                border-left-width: 0 !important;
            }
        }
    }

    .p-datatable-frozen-view {
        tr {
            &:hover {
                cursor: pointer;
            }
        }
    }

    tr {
        white-space: nowrap;
    }
}

.p-datatable-frozen-view > .p-datatable-scrollable-body > table > .p-datatable-tbody > tr > td:last-child {
    border-right: 1px solid $color-gray-2 !important;
}

.p-datatable-row-hover {
    background: rgba(0, 0, 0, 0.04) !important;
    color: rgba(0, 0, 0, 0.87) !important;
}
