// Global cross-vendor variables (to be used in bootstrap as well as in PrimeNG)

// Colors
$color-primary: #d9000d;
$color-black: #272727;
$color-white: #ffffff;
$color-gray-1: #f5f5f5;
$color-gray-2: #dadada;
$color-gray-3: #9e9e9e;
$color-gray-4: #4d4d4d;
$color-gray-5: #eff3f6;

$color-status-1: #83bf13;
$color-status-2: #379ac9;
$color-status-3: #d93f00;
$color-status-4: #38bfff;
$color-status-5: $color-black;
$color-status-6: #e8be44;
$color-status-7: $color-gray-3;
$color-status-8: $color-gray-2;
$color-status-9: #13bfb1;

$font-family: "Montserrat", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$header-height: 60px;

$container-padding: 24px;
$container-max-width: 1440px;

$z-index: (
    header: 10,
    nav: 9,
);

$radioCheckSize: rem-calc(20px);
$radioCheckBorder: 1px solid $color-gray-3;

$squareIconButtonSize: 30px;

$color-body-bg: $color-white;

$borderRadius: 7px;
